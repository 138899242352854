@import url("https://fonts.googleapis.com/css2?family=Edu+NSW+ACT+Foundation:wght@400;500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Raleway:wght@100&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
}

.main-header {
  width: 100%;
  /* background-image: url("Component/images/main_banner.jpg"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 210px;
}

.linking p {
  margin: 0 !important;
  font-size: 13px;
  text-decoration: none;
  color: black;
}

.linking a {
  text-decoration: none;
  color: black;
}

.logo {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* vertical-align:bottom; */
}

.logo img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.property {
  /* position: absolute; */
  /* top: -55px;
  left: 125px; */
  font-size: 35px;
  color: #fff;
  font-weight: 400;
  margin-top: 5%;
  margin-left: 3%;
  height: 50%;
}

.linking {
  border-radius: 10px;
}

.details p {
  font-size: 15px;
  padding: 0px 55px 0px 55px;
  text-align: justify;
}

.logo-prprty-col {
  position: relative;
}

.locate-pin {
  margin-left: 15%;
  position: absolute;
  bottom: 13%;
}

.header-icon-link1 {
  position: absolute;
  left: 36%;
  bottom: 11%;
}

.header-icon-link1 img {
  width: 65px;
  height: 30px;
}

.header-icon-link2 {
  position: absolute;
  left: 42%;
  bottom: 11%;
}

.header-icon-link2 img {
  width: 110px;
  height: 30px;
}


.Book a {
  text-decoration: none;
  color: rgb(43, 42, 42);
}

.linking {
  border: 1px solid transparent;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.linking:hover {
  /* border: 1px solid #8e8e93; */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.form-floating input {
  width: 100%;
  padding: 3px 0;
  border: 0;
  border-bottom: 1px solid #8e8e93;
  background-color: transparent;
  color: #8e8e93 !important;
  border-radius: 0;
}

.form-floating input:focus {
  background-color: transparent;
  border-color: #8e8e93;
}

.form-floating>label {
  top: 0px;
  font-size: 15px !important;
  color: #a8a8a8;
  padding: 20px 0px 0px 0px !important;
}

/* .login-form-image img{
  height: 100%;
} */
.continoue-btn button {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 30px;
  transition: all 0.2s;
  background-color: rgb(105, 76, 178);
  color: white;

  cursor: pointer;
}

.continoue-btn button:disabled,
button[disabled] {
  color: #a8a8a8;
  background-color: #dedede;
}

.continoue-btn button:hover {
  color: #8e8e93;
}

.continoue-btn button:focus {
  box-shadow: none !important;
}

form h6 {
  font-size: 12px;
  color: #8e8e93;
}

form h4 {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 500;
  font-size: 22px;
  font-family: "roboto", sans-serif;
}

.details p {
  color: #8e8e93;
  line-height: 1.5;
  font-size: 12px;
  padding-top: 20px;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

.login-row {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .timeslot-section{
  margin-right: 20px;
} */

/* otp css start */
.OtpMainRow {
  /* font-family: 'roboto', sans-serif; */
}

.OtpMainCol {
  padding: 6% 5% !important;
}

.OtpInputDiv input {
  border: none;
  /* background-color: #f5f5f5; */
  border-bottom: 1px solid #8e8e93;
  margin: auto !important;
  margin-top: 40px !important;
  color: #8e8e93;
}

.OtpInputDiv input:focus {
  outline: none;
}

.ResendOtp {
  margin-top: 30px !important;
  text-align: center;
  color: #8e8e93;
  font-size: 12px;
}

.ResendOtp a {
  color: rgb(105, 76, 178);
}

.OtpMainRow h4 {
  font-size: 22px;
}

.OtpMainRow h6 {
  color: #8e8e93;
  font-size: 12px;
  margin-top: 10px;
}

/* otp css end */
.SelectDate {
  /* height: 100% !important; */
  height: 445px !important;
}

.SelectTime {
  /* height: 100% !important; */
  height: 445px !important;
}

/* Booking Confirmation css start*/
.booking-main input {
  border-radius: 10px;
  color: #8e8e93;
}

.booking-main form input:focus {
  outline: none !important;
  color: #8e8e93;
}

.booking-main textarea {
  border-radius: 10px;
  color: #8e8e93;
}

.booking-main textarea:focus {
  color: #8e8e93;
  outline: none !important;
}

.booking-main .button-row button:nth-child(1) {
  background-color: transparent;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s;
  color: rgb(105, 76, 178);
  border: 1px solid rgb(105, 76, 178);
}

.booking-main .button-row button:nth-child(2) {
  background-color: rgb(105, 76, 178);
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s;
  color: #fff;
  border: none;
}

.booking-main span {
  font-weight: normal;
  color: #8e8e93;
}

.booking-main p {
  color: #8e8e93;
  font-weight: bold;
}

.booking-main button:hover {
  color: #fff;
}

.booking-main .form-row-2 select {
  margin-top: 32px;
  border-radius: 10px;
  color: #8e8e93;
}

.booking-main .form-row-2 select:focus {
  color: #8e8e93;
  outline: none !important;
}

.booking-number {
  color: #080808 !important;
}

.desktop-calender {
  display: block;
}

.accordion-main {
  display: none;
}

.booking-number {
  color: #080808;
  font-weight: normal;
}

/* Video Section Css Start */
.video-heading-row {
  margin-top: 5% !important;
  margin-bottom: 5% !important;
}

.video-row {
  text-align: center;
  margin-bottom: 10% !important;
}

.video-row iframe {
  border-radius: 12px;
  width: 100%;
  height: 600px;
}

.video-row h4 {
  text-align: left;
}

/* /* Video Section Css End */

/* ViewFloor Css Start */
.LightBoxCol {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  position: relative;
}

.LightBoxCol .big-img {
  width: 80%;
  /* height: 600px; */
  border-radius: 10px;
  object-fit: fill;
}

.ImgOverlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  border-radius: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
}

.ImgOverlay:hover {
  opacity: 1;
}

.ImgOverlay img {
  width: 70px;
  height: 70px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translateY(-50%);
  margin: auto;
}

/* ViewFloor Css End */
/* RecordedVideos css start  */
.policy-modal .modal-body {
  /* padding-right: 5px; */
  padding: 5px 10px 7px 20px;
}

.policy-modal .modal-body p {
  margin-bottom: 0px;
  /* margin-right: 3px; */
}

.policy-modal .modal-content {
  /* border-radius: 20px; */
}

.date-picker input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: .8;
  display: block;
  background: url(../src/Component/images/calendar-days-solid.svg) no-repeat;
  width: 15px;
  height: 15px;
  border-width: thin;
}

.date-picker .react-date-picker__wrapper {
  border: none;
}

.date-picker .react-date-picker__clear-button {
  display: none;
}

.date-picker .react-calendar {
  padding: 5px !important;
}

.date-picker .react-calendar button {
  margin-top: 5px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}

/* .date-picker .react-date-picker__calendar-button{
  background: url('../src/Component/images/booking-event-calendar_23-2148551245.jpg') !important;
  background-repeat: no-repeat;
} */
.date-picker .react-calendar {
  border: none !important;
  box-shadow: 0 9px 24px rgb(0 0 0 / 20%) !important;
  /* margin-bottom: 2px !important; */
}


.formbuilder-datepicker input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: .8;
  display: block;
  background: url(../src/Component/images/calendar-days-solid.svg) no-repeat;
  width: 15px;
  height: 15px;
  border-width: thin;
}

.formbuilder-datepicker .react-date-picker__wrapper {
  border: none !important;
}

.formbuilder-datepicker .react-date-picker__clear-button {
  display: none;
}

.formbuilder-datepicker .react-calendar {
  padding: 5px !important;
  border: none;
}

.formbuilder-datepicker .react-calendar button {
  margin-top: 5px !important;
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.formbuilder-datepicker .react-calendar {
  border: none !important;
  box-shadow: 0 9px 24px rgb(0 0 0 / 20%) !important;
  /* margin-bottom: 2px !important; */
}

.policy-modal-btn {
  border: none;
  background-color: rgb(105, 76, 178) !important;
  border-radius: 30px;
  padding: 8px 20px;
}


.rental-modal .modal-content {
  width: 80% !important;
  margin: auto;
}

.rental-modal .modal-body {
  padding-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 5px;
  /* margin-right: 6px !important; */
}

.rental-modal .modal-header {
  padding: 15px 20px;
}

.rental-modal .modal-body .form-group {
  margin: 20px 0px;
}

.rental-modal .modal-body .form-group input {
  margin-bottom: 5px;
}

.whatsapp-checkbox label {
  font-weight: normal;
  opacity: .8;
  font-size: 14px;
}

.whatsapp-checkbox input {
  height: 15px;
  width: 15px;
  border-color: rgb(105, 76, 178) !important;
  border-radius: 4px !important;
  margin-top: 5px !important;
}

.whatsapp-checkbox input:checked {
  background-color: rgb(105, 76, 178);
}

/* .rental-modal .modal-body .form-group label{
  margin: 0px 0px 5px 0px !important;
} */
.rental-modal .form-label {
  margin-bottom: 2px !important;
  font-weight: normal;
  opacity: .8;
}

.rental-modal .form-error {
  color: red !important;
  font-size: 15px;
  margin-top: 2px;
}

.rental-button-div {
  text-align: end;
  padding-right: 10px;
}

.rental-modal-btn {
  border: none;
  background-color: rgb(105, 76, 178) !important;
  border-radius: 30px;
  padding: 8px 20px;
  color: #fff !important;
}

.modal-btn-col {
  text-align: end;
  width: fit-content;
  margin-left: auto;
}
.virtual-video-heading-col{
  width: fit-content;
}

/* .rental-form input {
  width: 70% !important;
} */
/* .rental-form .form-group p {
  color: red;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
} */
.ml-8 {
  margin-left: 8px !important;
}

.logo-left-col {
  margin-top: -7%;
}

.form-builder-btn{
  text-align: end;
}
.form-builder-btn Button{
  background-color: rgb(105, 76, 178) !important;
  border: none;
  border-radius: 30px;
  padding: 8px 20px;
  color: #fff !important;
}
.form-builder-btn Button:hover {
  background-color: rgb(105, 76, 178) !important;
  color: #fff !important;
}
.rental-loader{
  height: 14px !important;
  width: 14px !important;
}

.property-link-card{
  display: flex;
  flex-direction: row;
  /* padding: 15px; */
  /* border: none; */
  border-radius: 10px;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
  /* transition: 0.4s; */
  margin-bottom: 20px;
  padding-left: 5px;
}
/* .property-link-card:hover{
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s;
} */
.property-link-card img{
  margin-right:5px;
  /* vertical-align: middle; */
  width: 40px;
  height: 40px;
  border-radius: 10px 0px 0px 10px;
}
.property-link-card span{
  /* vertical-align: middle; */
  font-size: 18px;
  padding: 15px 0px;
}

.no-property-text{  
  text-align: center;
}

.vertual-view-heading{
  margin: 35px 0px !important;
}


/* RecordedVideos css end  */
@media (min-width: 0px) and (max-width: 480px) {
  .logo {
    width: 70px !important;
    height: 70px !important;
  }
  .logo-left-col{
   margin-top: -10%;
  }
  .property {
    margin-top: 2%;
    font-size: 18px;
  }

  .logo {
    width: 50px !important;
    height: 50px !important;
    border-radius: 8px;
  }

  .logo img {
    border-radius: 8px;
  }

  .link-button-col {
    padding-left: 0px;

    padding-top: 2%;
  }

  .link-btn1 {
    margin: 0px;
    padding: 0px;
  }

  .link-btn2 {
    margin: 0px;
    padding: 0px;
  }

  .locate-pin {
    margin-left: 20%;
    position: absolute;
    bottom: 40%;
    top: 3px;
    font-size: 14px;
  }

  .logo-prprty-col {
    padding: 0px;
  }

  .time-col {
    margin-top: 25px;
    padding: 8px;
  }

  .desktop-calender {
    display: none !important;
  }

  .mobile-calender {
    margin-top: 4%;
    /* border: 2px solid #5593ff; */
  }

  .mobile-calendar-time-slot span {
    border: 2px solid #5593ff !important;
    color: rgb(105, 76, 178);
  }

  .mobile-calendar-time-slot span:hover {
    /* border: 2px solid #5593ff !important; */
  }

  .accord-div {
    margin-top: 5%;
    /* border: #dadadf solid 2px; */
    border-radius: 15px;
    padding: 50px 20px;
    background-color: #fff;
  }

  .accord-div h3 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 25px;
  }

  .showMoreDaysDiv {
    text-align: center;
    padding-top: 20px;
  }

  .showMoreDays {
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
    color: #5593ff;
  }

  .showMoreDays:hover {
    color: #5593ff;
  }

  .accordion-main {
    display: block !important;
    margin-top: 15%;
  }

  .BookAView {
    width: 100%;
    text-align: center;
  }

  .bottom-row {
    padding-top: 5% !important;
  }

  .NextBtn {
    width: 90%;
    padding: 8px;
    text-decoration: none;
    color: #fff;
    background-color: rgb(105, 76, 178);
    border: none;
    border-radius: 30px;
    font-size: 14px;
  }

  .card-header {
    background-color: transparent;
  }

  .calender-container {
    padding: 0px;
  }

  .video-row iframe {
    border-radius: 12px;
    width: 100%;
    height: 250px;
  }

  .video-heading-row {
    margin-top: 3% !important;
    margin-bottom: 5% !important;
  }

  .LightBoxCol .big-img {
    width: 100%;
    /* height: 230px; */
    border-radius: 10px;
  }

  .ImgOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .ImgOverlay img {
    width: 50px;
    height: 50px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }

  .date-picker .react-calendar button {
    margin-top: 5px !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .middle-section {
    padding: 10px;
  }

  .property {
    font-size: 25px;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .main-header {
    background-size: 100% 73%;
  }

  .property {
    margin-top: 3%;
  }

  .locate-pin {
    margin-left: 20%;
    position: absolute;
    bottom: 95%;
    font-size: 14px;
  }

  .link-btn1 {
    margin: 0px;
    padding: 0px;
  }
  .logo-left-col {
    margin-top: -16%;
  }

  .link-btn2 {
    margin: 0px;
    padding: 0px;
  }

  .time-col {
    margin-top: 25px;
    padding: 8px;
  }

  /* .desktop-calender {
    display: none !important;
  } */
  .mobile-calender {
    margin-top: 4%;
    /* border: 2px solid #5593ff; */
  }

  .mobile-calendar-time-slot span {
    border: 2px solid #5593ff !important;
    color: rgb(105, 76, 178);
  }

  .mobile-calendar-time-slot span:hover {
    /* border: 2px solid #5593ff !important; */
  }

  .accord-div {
    margin-top: 5%;
    /* border: #dadadf solid 2px; */
    border-radius: 15px;
    padding: 50px 30px;
    background-color: #fff;
  }

  .accord-div h3 {
    text-align: center;
    font-size: 22px;
    margin-bottom: 25px;
  }

  .accord-div .card-header {
    background-color: none !important;
    border: none !important;
  }

  .showMoreDaysDiv {
    text-align: center;
    padding-top: 20px;
  }

  .showMoreDays {
    font-size: 14px;
    text-decoration: none;
    font-weight: bold;
    color: #5593ff;
  }

  .showMoreDays:hover {
    color: #5593ff;
  }

  .accordion-main {
    display: block !important;
    margin-top: 15%;
  }

  .BookAView {
    width: 100%;
    text-align: center;
  }

  .bottom-row {
    padding-top: 5% !important;
  }

  .NextBtn {
    width: 70%;
    padding: 8px;
    text-decoration: none;
    color: #fff;
    background-color: rgb(105, 76, 178);
    border: none;
    border-radius: 30px;
    font-size: 14px;
  }

  .card-header {
    background-color: transparent;
  }

  .video-row iframe {
    border-radius: 12px;
    width: 100%;
    height: 300px;
  }

  .LightBoxCol .big-img {
    width: 99%;
    /* height: 330px; */
    border-radius: 10px;
  }

  .ImgOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .ImgOverlay img {
    width: 60px;
    height: 60px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .details p {
    padding: 0 !important;
  }

  .middle-section {
    padding: 50px;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .property {
    margin-top: -2%;
  }

  .locate-pin {
    margin-left: 14%;
    position: absolute;
    bottom: -20%;
  }

  .link-btn1 {
    margin: 0px;
    padding: 0px;
  }

  .link-btn2 {
    margin: 0px;
    padding: 0px;
  }

  .video-row iframe {
    border-radius: 12px;
    width: 100%;
    height: 400px;
  }

  .LightBoxCol .big-img {
    width: 99%;
    /* height: 400px; */
    border-radius: 10px;
  }

  .ImgOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .ImgOverlay img {
    width: 70px;
    height: 70px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .logo {
    width: 100px;
    height: 100px;
  }

  .property {
    margin-top: 1%;
  }

  .locate-pin {
    margin-left: 13%;
    position: absolute;
    bottom: 12%;
  }

  .ImgOverlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .LightBoxCol .big-img {
    width: 99%;
    /* height: 600px; */
    border-radius: 10px;
  }

  .ImgOverlay img {
    width: 70px;
    height: 70px;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
  }
}

@media (min-width: 1400px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px !important;
  }

}

.react-calendar {
  width: 100% !important;
  margin-top: 8%;
}

.react-calendar__navigation__label span {
  font-weight: 500 !important;
  font-size: 20px;
}

.react-calendar__tile--active {
  border-radius: 10px !important;
}

.react-calendar__month-view__days {
  padding-bottom: 20px !important;
}

.form-floating input:focus {
  box-shadow: none !important;
}

.login-form {
  font-family: "Roboto", sans-serif;
}

.login-row {
  box-shadow: none;
}

.log-in-col {
  /* background-color: #fff; */
}

@media (max-width: 450px) {
  .login-form {
    height: 100%;
  }

  .login-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .email-col {
    padding: 0px 25px !important;
  }
}

@media (min-width: 478px) and (max-width:767px) {
  .login-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .email-col {
    padding: 0px 25px !important;
  }

}
@media (max-width:767px){
  .vertual-view-heading{
    margin: 0px 0px 30px 0px !important;
}
.virtual-video-heading-col{
  padding-bottom: 20px;
}
.in-person-viewing-heading{
  padding-bottom: 10px;
}
}

#booknow {
  width: 100% !important;
  height: 40px;
}

.video-view-continer .calendar {
  width: 100% !important;
}

/* booking-history page css start */
.booking-details {
  font-family: "Roboto", sans-serif;
}

.booking-details button {
  font-size: 15px;
}

.booking-details h5 {
  font-weight: 600;
  font-size: 17px;
}

.booking-details h6 {
  font-size: 18px;
}

.booking-history {
  border-top: 1px solid rgb(215, 209, 209);
}

.history-heading h6,
.buttons {
  font-size: 15px;
  color: grey;
}

/* .booking-main{
  padding: 20px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  
} */
.history-booking-page {
  font-family: "Roboto", sans-serif;
  display: none;
  font-size: 17px;
  font-weight: 500;
}

.history-booking-page .accordion-item {
  background-color: transparent !important;
  border: none !important;
}

.history-booking-page .card {
  background-color: transparent !important;
  border: none !important;
  padding: 10px;
}

.modal-header,
.modal-footer {
  border: none !important;
}

.booking-history-card {
  box-shadow: 0 12px 24px rgba(127, 125, 125, 0.2);

}

.main_booking {
  font-family: 'Roboto', sans-serif;
}

.bookings-btn {
  margin-top: -5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #A596FF;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  /* z-index: -1; */
}

.booking-history-Div {
  background: #fff;
  border-radius: 13px;
  z-index: 1;
}

.history_startTime {
  font-weight: 400;
}

.history_property_name {
  font-size: 18px;
  font-weight: 400;
}

.booking-history-Div svg {
  margin-top: -6px !important;
}

.reschedule-btn,
.Cancel-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}

.datehistorytime {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #252525;
}

.historydate {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
}

.Activebtn {
  background-color: #C8E8A8 !important;
  color: black;
  /* padding: 10px; */
  border-radius: 20px;
  font-size: 16px;
  border: none;
  text-transform: capitalize;
  padding: 2.5px 25px;
}
.inactivebtn{
  background-color: #FF9D9D !important;
  color: black;
  /* padding: 10px; */
  border-radius: 20px;
  font-size: 16px;
  border: none;
  text-transform: capitalize;
  padding: 2.5px 25px;
}

/* booking-history page css end */


@media(min-width: 768px) {
  .inactivebtn, .Activebtn {
    line-height: 31px;

  }
}
@media(max-width: 767px) {
  .main-header {
    min-height: 100px;
  }

  /* .logo-left-col {
    margin-top: -15%;
  } */

  .rental-modal-btn {
    /* margin-top: 5px !important; */
  }

  .history-booking-page {
    margin-top: 30px;
  }

  .history-booking-page {
    display: block;
  }

  .history-booking-page {
    display: block !important;
  }

  .datehistorytime {
    font-size: 16px;
    line-height: 22px;
  }

  .history_property_name {
    font-size: 16px;
  }
  
  .historydate {
    font-size: 18px;
  }

  .Activebtn {
    max-height: 30px;
  }

  .inactivebtn {
    max-height: 30px;

  }
  .modal-btn-col {
   margin-left: 0px;
   padding-left: 0px;
  }
  .video-heading-row{
    justify-content: center;
  }

  .rental-modal .modal-content{
    width: 100% !important;
  }
}