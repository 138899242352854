body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    position: relative;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid lightgray;
    background: white;
}

/* GRID */
.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

/* Calendar */
.calendar {
    display: block;
    position: relative;
    background: white;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    height: auto;
    margin: 0 auto;
    border-radius: 16px;
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}


.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: gray;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid lightgray;
}

.calendar .calendar-body .cell {
    position: relative;
    height: 3em;
    /* border-right: 1px solid lightgray; */
    overflow: hidden;
    cursor: pointer;
    /* background: white; */
    transition: 0.25s ease-out;
    text-align: center;
}

.calendar .calendar-body .number:hover {
    background: whitesmoke;
    transition: 0.5s ease-out;
}

.calendar .calendar-body .selected .number,
.calendar .calendar-body .selected:hover .number {
    background-color: #ff374b;
    color: white;
}

.calendar .calendar-body .row {
    border-bottom: 1px solid lightgray;
}

.calendar .calendar-body .row:last-child {
    border-bottom: none;
}

.calendar .calendar-body .cell:last-child {
    border-right: none;
}

.calendar .calendar-body .number {
    line-height: 3em;
    font-weight: 600;
    /* width: 64px; */
    border-radius: 16px;
    cursor: pointer;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


.calendar .calendar-body .disabled {
    color: lightgray;
    pointer-events: none;
}

.calendar .calendar-body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: #1affa0;
    opacity: 0;
    font-size: 4em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
}


.calendar .calendar-body .column {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
    text-align: center;
}

.col-end {
    text-align: end;
}

.col-center {
    text-align: center;
}

.calendar .col {
    padding: 5px;
}

.mobile-calendar-time-slot span:nth-child(1) {
    border: 1px solid rgb(28, 28, 27);
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
}

.mobile-calendar-time-slot span:nth-child(2) {
    border: 1px solid rgb(28, 28, 27);
    padding: 5px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
}

.calendar-current-day {
    font-weight: 600;
}

.card>div:focus {
    border: 1px solid blue !important;
}

.card-header {
    font-weight: 500;
}

.date-fix {
    position: absolute;
    right: 10px;
    font-size: 14px;
    top: 50%;
    font-weight: 600;
    transform: translateY(-50%);
}

.date-badge {
    font-size: 12px;
    background-color: #6A5E89;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
}

.disabled-left-icon {
    opacity: .5;
    cursor: default !important;
}

.mobile-calender .card {
    margin-bottom: 10px;
    /* background-color: #373C4F;
    color: white; */
}

.accord-div {
    display: none;
}

@media screen and (max-width: 420px) {
    .date-fix {
        font-size: 11px;
        right: 0px;

    }

    .calendar-current-day {

        font-weight: 500;
        font-size: 13px;
    }

    .card-header {
        padding: 10px 0px 10px 7px;
    }

    .lastDays {
        font-size: 13px;
    }
}

@media screen and (max-width: 570px) {
    .calendar {
        display: none;
    }

    .Virtualhighlight {
        text-align: center !important;
    }

    .view-calendar-calendar {
        display: none;
    }

    .accord-div {
        display: block;
        margin-top: 0;
        padding-top: 0;
    }

    .accordion-main {
        margin-top: 0px !important;
    }

    .view-calendar-calendar .calendar {
        display: block;
    }


}

@media screen and (max-width: 767px) {

    .view-calendar-container {
        max-width: 610px !important;
    }

}

.video-viewing-number {
    line-height: 3em;
    font-weight: 600;
    border-radius: 16px;
    cursor: pointer;
    padding-left: 27px;
    padding-right: 29px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.lastDays {
    color: lightgrey;
}