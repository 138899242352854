.responsive-calendar-time-slot .col {
    border: 2px solid #5593ff !important;
    color: #694cb2;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 4px;
    font-size: 12px;
}

.accordion-content {
    transition: .3s height ease;
}

.accordion-content {
    transition: .3s all ease;
}

.accordion-date {
    padding: 10px;
    font-weight: 600;
}

.accordion-item {
    margin-bottom: 10px;
    font-size: 13px;
}