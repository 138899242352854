.time-section {
  padding: 20px;
}

.timeslot {
  margin-right: 10px;
  border-color: rgba(0, 105, 255, 0.5) !important;
}

.timeslot:hover {
  border-color: rgba(0, 105, 255) !important;

}

.react-calendar {
  border-radius: 4px;
}

.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation button {
  color: #6f48eb;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  /* border-radius: 6px; */
}

.react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  /* border-radius: 6px; */
  font-weight: bold;
  color: #6f48eb;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}



.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #6f48eb;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #6f48eb;
  color: white;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar button {
  border: 0;
  outline: none;
  border-radius: 20px;
  padding: 8px;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__days__day {
  background-color: white;
}


.react-calendar__tile--active {
  background: #6f48eb;
  /* border-radius: 6px; */
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
}

.react-calendar__tile {
  margin: 10px 20px;
  max-width: 44px;
}

.timeslot-section {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  padding: 20px;
  background-color: white;
  border-radius: 16px;
  /* height: 485px !important; */
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.timeslot-btn {
  padding: 13px 10px;
  border: 2px solid #5593ff;
  color: #004bcc !important;
  box-shadow: none !important;
}

.timeslot-btn:hover {
  border-color: #0041b3;
}

.btn {
  box-shadow: none !important;
}

.submit-btn {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 7px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 30px;
  transition: all 0.2s;
  background-color: rgb(105, 76, 178) !important;
  color: white;

  cursor: pointer;
  box-shadow: none !important;
  border: none !important;
}

.font-weight-bold {
  font-weight: bold;
}

.form-error {
  color: red
}

input {
  box-shadow: none !important;
}

.ring-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.ring-loader span {
  width: 125px !important;
  height: 125px !important;
}

.phone-number input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;


}

.num-check {
  font-size: 12px;
}

.otp-status a {
  color: #FF385C;
  cursor: pointer;
}

.otp-status {
  padding: 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, .7);
  font-weight: 400;
  text-align: center;
}